import styles from '@buddieshr/styles/components/benefits.module.scss';
import Image from 'next/image';
import PageWrapper from './templates/pageWrapper';

const BenefitsVignette = ({ icon, title, description }) => (
  <div className={styles.vignetteWrapper}>
    <div className={styles.vignetteInner}>
      <div className={styles.vignetteIcon}>{icon}</div>
      <div className={styles.content}>
        <h3 className={styles.vignetteTitle}>{title}</h3>
        <div className={styles.vignetteDescription}>{description}</div>
      </div>
    </div>
  </div>
);

const Benefits = ({ app }) => {
  const { benefits, name } = app;
  return (
    <div className={styles.wrapper}>
      <PageWrapper width={700}>
        <div className={styles.titleWrapper}>
          <h2 className={styles.title}>
            {`What makes ${name || 'us'} shine`}&nbsp;
            <Image
              src="/img/animated-emojis/Glowing%20Star.png"
              alt="Glowing Star"
              width="40"
              height="40"
            />
          </h2>
          {!name && (
            <div className={styles.subtitle}>
              Our Slack apps do the heavy lifting, so you can focus on the fun
              stuff, like actually building a great team.
            </div>
          )}
        </div>

        <div className={styles.list}>
          {benefits.map((b) => (
            <BenefitsVignette
              key={b.title}
              icon={b.icon}
              title={b.title}
              description={b.description}
            />
          ))}
        </div>
      </PageWrapper>
    </div>
  );
};

export default Benefits;
